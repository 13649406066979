/* eslint-disable */
import { ReactComponent as Logo } from '../../assets/icons/invocom-icon-chat.svg';
import React, { useState } from 'react';
import { isValidEmail, countryCodes } from '../../helpers/date-conversions';
import Select from 'react-select';
import nextArrow from '../../assets/icons/next-arrow.svg';

const Box = ({
  error,
  state,
  value,
  placeHolder,
  label,
  statement,
  setComponents,
  codeState,
  selectedOption = null,
  setSelectedOption = null,
  formHandler,
  setError,
  code,
}) => {
  const [emailText, setEmailText] = useState('');
  const [nameText, setNameText] = useState('');
  const [phoneText, setPhoneText] = useState('');

  const handleText = async () => {
    if (label === 'Email') {
      let updatedEmailError = '';

      if (value === '') {
        updatedEmailError = 'This field is required.';
      } else if (value !== '' && !isValidEmail(value)) {
        updatedEmailError = 'Invalid Email';
      }
      if (updatedEmailError === '') {
        setEmailText(value);
        setComponents((prev) => [...prev, 'fullname']);
      } else {
        setError(updatedEmailError);
      }
    } else if (label === 'Fullname') {
      let updatedNameError = '';
      const alphabetPattern = /^[A-Za-z]+$/;
      if (!alphabetPattern.test(value)) {
        updatedNameError = 'Only alphabets are allowed in the name.';      
      }
      if (value === '') {
        updatedNameError = 'This field is required.';
      }
      if (updatedNameError === '') {
        setNameText(value);
        setComponents((prev) => [...prev, 'phone']);
      } else {
        setError(updatedNameError);
      }
    } else if (label === 'Phone') {
      let updatedContactError = '';
      if (code === '' && value !== '') {
        updatedContactError = 'Please select country code';
      } else if ((code !== '' || code === 'Reset') && value === '') {
        updatedContactError = 'Please enter phone number';
      }

      if (updatedContactError === '') {
        setPhoneText(value);
        formHandler();
      } else {
        setError(updatedContactError);
      }
    }
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    const maxLength = event.target.maxLength;

    if (inputValue.length <= maxLength) {
      const sanitizedCode = event.target.value.replace(/[^0-9]/g, '');
      state(sanitizedCode);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    codeState(selectedOption.value);
  };

  const handleReset = () => {
    setSelectedOption(null);
    codeState('');
  };

  const options = countryCodes
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((country) => ({
      value: country.code,
      label: `(${country.code}) ${country.name}`,
    }));

  const formatOptionLabel = ({ value, label, country }) => (
    <div>
      <span>{label}</span>
      <span style={{ marginLeft: '5px' }}>{country}</span>
    </div>
  );
  return (
    <div className="d-flex" style={{ width: label === 'Phone' ? '92%' : '95%', marginTop: '20px' }}>
      <div className="bot-container">
        <Logo />
      </div>
      <div className="message-container" style={{ marginLeft: '5px', width: 'inherit' }}>
        <p>{statement}</p>
        <div className="input-bg">
          {emailText ? (
            <>
              <label>{label}</label>
              <input className="formInput pl-0 disabled-input" type="text" value={`${value} ✅`} disabled={true} />
            </>
          ) : nameText ? (
            <>
              <label>{label}</label>
              <input className="formInput pl-0 disabled-input" type="text" value={`${value} ✅`} disabled={true} />
            </>
          ) : phoneText ? (
            <>
              <label>{label}</label>
              <input className="formInput pl-0 disabled-input" type="text" value={`${code} ${value} ✅`} disabled={true} />
            </>
          ) : label === 'Phone' ? (
            <>
              <label>
                {label} {label === 'Phone' ? '(Optional)' : ''}
              </label>
              <div className="contact-flex">
                <div style={{ border: 'none', width: '220px' }}>
                  <Select
                    options={options}
                    value={selectedOption}
                    onChange={handleSelectChange}
                    isSearchable={true}
                    placeholder="Code"
                    formatOptionLabel={formatOptionLabel}
                    // classNamePrefix='custom-select'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        border: 'none',
                        boxShadow: 'none',
                        '&:focus': {
                          border: 'none',
                          boxShadow: 'none',
                        },
                      }),
                    }}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <input
                    className="formInput pl-0 mt-0"
                    name="contact"
                    style={{ border: 'none' }}
                    type="text"
                    placeholder="Phone (optional)"
                    value={value}
                    autoComplete="off"
                    maxLength={25}
                    onChange={(e) => (label === 'Phone' ? handleChange(e) : state(e.target.value))}
                  />
                </div>
              </div>
              {selectedOption && (
                <button type="button" className="reset" onClick={handleReset}>
                  Reset Code
                </button>
              )}
              <div className="mb-1">
                {error ? (
                  <div style={{ color: 'red', fontSize: '12px' }} className="mb-2">
                    {error}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            <>
              <label>{label}</label>
              <div className="d-flex">
                <input
                  className={`formInput mb-3 ${error && 'mb-2'}`}
                  placeholder={placeHolder}
                  value={value}
                  maxLength={30}
                  autoComplete="off"
                  onChange={(e) => state(e.target.value)}
                />
                {emailText ? (
                  ''
                ) : nameText ? (
                  ''
                ) : phoneText ? (
                  ''
                ) : (
                  <button
                    onClick={() => {
                      handleText();
                    }}
                    className="submitBtnSmall mt-2"
                  >
                    <span>
                      <img src={nextArrow} alt="icon" className="cursor-pointer"  />
                    </span>
                  </button>
                )}
              </div>
            </>
          )}
          {label === 'Phone' ? (
            ''
          ) : error ? (
            <div style={{ color: 'red', fontSize: '12px' }} className="mb-2 ml-2">
              {error}
            </div>
          ) : (
            ''
          )}
        </div>
        {}
        {emailText ? (
          ''
        ) : nameText ? (
          ''
        ) : phoneText ? (
          ''
        ) : label === 'Phone' ? (
          <>
            <div className="d-flex justify-center calendar-footer container">
              <button
                onClick={() => {
                  handleText();
                }}
                style={{ height: '40px' }}
                className="cancel-btn-calendar mt-2 p-0 w-100"
              >
                Skip
              </button>
              <button
                onClick={() => {
                  handleText();
                }}
                className="submitBtn mt-2"
              >
                Submit
              </button>
            </div>
          </>
        ) : value ? (
          ''
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Box;
