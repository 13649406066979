/* eslint-disable */
import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import { io } from 'socket.io-client';
import Picker from 'emoji-picker-react';
import { useFilePicker } from 'use-file-picker';
import MicRecorder from 'mic-recorder-to-mp3';
import stopRecorder from '../../assets/icons/meeting.svg';
import loadingGif from '../../assets/icons/loading.gif';
import { useLocation } from 'react-router-dom';
import Footer from '../FooterInput';
import { UploadFileToS3 } from '../../service/s3-upload';
import { UploadAudioToS3 } from '../../service/audio-upload';
import { LocalTimeFormat, getEntityId } from '../../helpers/date-conversions';
import CustomAlertModal from '../modal/CustomAlertModal';
import ConfirmationModal from '../modal/ConfirmationModal';
import cross1 from '../../assets/icons/cross-icon1.svg';

import { ReactComponent as Logo } from '../../assets/icons/invocom-icon-chat.svg';
import microPhone from '../../assets/icons/microPhone.svg';
import attachment from '../../assets/icons/attachment.svg';
import faceIcon from '../../assets/icons/Face-Icon.svg';
import sendButton from '../../assets/icons/sendButton.svg';
import Question from '../../screens/WelocomScreen/question';
import Tooltip from "../Tooltip/index";

require('dotenv').config();

const Chat = ({
  setIsNewMessage,
  setUserChatId,
  selectedAnswers,
  questions,
  isNewMessage,
  setStatus,
  setIsChatAssign,
  userChatId,
}) => {
  const MAX_FILE_COUNT = 4;
  const sound = './Email.mp3';

  const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
  const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;
  const [record, setRecord] = useState(false);
  const [chat, setChat] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [assign, setAssign] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [language, setLanguage] = useState(localStorage.getItem(`${getEntityId()}:language`));
  const [chatStatus, setChatStatus] = useState('');
  const [showEmoji, setShowEmoji] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(localStorage.getItem(`${getEntityId()}:id`));
  const [chatId, setChatId] = useState();
  const userEmail = useState(localStorage.getItem(`${getEntityId()}:userEmail`));
  const isQuestions = JSON.parse(localStorage.getItem(`${getEntityId()}:questions`));
  const welcomeMessage = useState(JSON.parse(localStorage.getItem(`${getEntityId()}:welcomeStatement`)));
  const [loading, setLoading] = useState(true);
  const [audioLoading, setAudioLoading] = useState(false);
  const [animation, setAnimation] = useState(false);
  const entityRecord = localStorage.getItem(`${getEntityId()}:entityId`);
  const entityUrl = window.document.referrer ? new URL(window.document.referrer).origin : window.location.url;
  const [isSend, setIsSend] = useState(false);
  const [isSendImage, setIsSendImage] = useState(false);
  const focusDiv = useRef();
  const fullname = localStorage.getItem(`${getEntityId()}:fullname`);
  const [audioTime, setAudioTime] = useState('00:00');
  const [show, setShow] = useState(false);
  const [chatLoader, setChatLoader] = useState(false);
  const timer = useRef(null); // Ref for interval object
  const languageRef = useRef(null);
  const time = useRef(0); // Stores the value of time
  const [isRecording, setIsRecording] = useState(null);
  const [allPlainFiles, setAllPlainFiles] = useState([]);
  const [allFilesContent, setAllFilesContent] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();

  const handleFileChange = ({ plainFiles, filesContent, errors }) => {
    if (plainFiles?.length) {
      setAllPlainFiles(allPlainFiles.length ? allPlainFiles.concat(plainFiles) : plainFiles);
      setAllFilesContent(allFilesContent.length ? allFilesContent.concat(filesContent) : filesContent);
    }
  };
  const [openFileSelector, { filesContent, plainFiles, clear, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ['.png', '.jpg', '.jpeg'],
    multiple: true,
    maxFileSize: 50,
    readFilesContent: true,
    limitFilesConfig: { min: 1, max: MAX_FILE_COUNT - allPlainFiles.length },
    onFilesSelected: handleFileChange,
  });

  const handleOpenModal = (value) => {
    setModalMessage(value);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setModalMessage('');
    setShowModal(false);
    clear();
  };

  const handleImageClick = (imageURL) => {
    const newWindow = window.open(imageURL, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const renderChat = (userChat) => {
    return userChat?.map((userChatMessage, index) => {
      if (userChatMessage.STATUS !== 'SPECIAL') {
        const isSender = userChatMessage.sender === userId;
        return (
          <div key={`${userChatMessage._id}--${index}`}>
            <div
              className={`${
                isSender ? 'd-flex justify-end' : userChatMessage?.message ? 'receiveMessageOuter' : 'd-flex justify-start'
              }`}
            >
              {!isSender ? <Logo className="logo" /> : ''}
              <div
                className={`${isSender && userChatMessage?.message ? 'sendMessage' : 'receiveMessage'}`}
                style={{ marginBotton: '15px', width: 'fit-content' }}
              >
                {userChatMessage?.image ? (
                  <div className="d-flex" style={{height: "150px"}} >
                    <img
                      src={userChatMessage.image}
                      alt=""
                      loading="lazy"
                      className="chat-image mb-1 img-fluid"
                      onClick={() => handleImageClick(userChatMessage?.image)}
                    />
                  </div>
                ) : userChatMessage?.audio ? (
                  <div>
                    <audio src={userChatMessage?.audio} controls style={{ width: '228px', marginBottom: '5px' }} />
                  </div>
                ) : (
                  ''
                )}
                {userChatMessage?.message ? <p>{userChatMessage?.message}</p> : ''}
                <span
                  className="date text-right mt-0.5 mb-3 mr-2"
                  style={{ color: isSender && userChatMessage?.message ? '#fffff' : '#afa7a7' }}
                >
                  {' '}
                  {userChatMessage?.createdAt ? LocalTimeFormat().format(new Date(userChatMessage?.createdAt)) : ''}{' '}
                </span>
              </div>
              <AlwaysScrollToBottom />
            </div>
            <AlwaysScrollToBottom />
          </div>
        );
      }
      return false;
    });
  };

  const handleQuestionSelect = async (question) => {
    if (question.nodes.length === 0) {
      selectedAnswers.push({ id: question.id, question: question.question });
      setCurrentQuestion(question);
      localStorage.setItem(`${getEntityId()}:isQuestionRead`, true);
      setIsNewMessage(false);
      setLoading(true);
      await sendChatData({
        message: selectedAnswers,
        email: userEmail,
        entity: entityRecord,
        status: true,
        entityUrl,
      });
      setLoading(false);
    } else {
      selectedAnswers.push({ id: question.id, question: question.question });
      setCurrentQuestion(question);
    }
  };

  const renderQuestions = () => {
    if (!currentQuestion) {
      return questions.map((question, index) => {
        return (
          <div key={`${question.id}_${index}`} className="d-flex justify-end">
            <div className="send-special-message" style={{ marginBotton: '15px' }}>
              <p onClick={() => handleQuestionSelect(question)}>{question.question}</p>
            </div>
          </div>
        );
      });
    } else {
      return (
        <>
          <div>
            <div className="mb-2">
              {selectedAnswers?.length > 0 &&
                selectedAnswers?.map((answer, index) => (
                  <div key={`${answer.id}-${index}`} className="d-flex justify-end">
                    <div className="send-special-message-selected" style={{ marginBotton: '15px' }}>
                      <p>{answer?.answer === null ? '' : answer.question}</p>
                    </div>
                    <AlwaysScrollToBottom />
                  </div>
                ))}
            </div>
            <Question question={currentQuestion} onSelect={handleQuestionSelect} selectedAnswers={selectedAnswers} />
          </div>
        </>
      );
    }
  };

  const skipQuestions = async () => {
    setIsNewMessage(false);
    await sendChatData({
      message: [],
      email: userEmail,
      entity: entityRecord,
      status: true,
      entityUrl,
    });
  };

  const sendChatData = async (chatDetails) => {
    try {
      const entity = 'chat';
      const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/usermessage`, {
        method: 'POST',
        body: JSON.stringify(chatDetails),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const chatsData = await response.json();
      if (response.status === 200) {
        setChatId(chatsData?.chats[0]._id);
        setUserChatId(chatsData?.chats[0]._id);
        if (chatsData?.chats[0]?.messages?.length > 0) {
          setUserId(chatsData?.chats[0].client._id);
          if (chatsData?.chats[0].STATUS === 'ASSIGNED') {
            setIsChatAssign(true);
            setStatus('Assigned');
            setAnimation(false);
            setLoading(false);
            setChatLoader(false);
          }
          setChat(chatsData?.chats[0]?.messages);
          setAssign(chatsData?.chats[0]);
          setLoading(false);
          setChatLoader(false);
        } else {
          setChat([]);
          setLoading(false);
          setChatLoader(false);
        }
      }
    } catch (error) {
      return false;
    }
  };

  const fetchChats = async () => {
    try {
      const entity = 'chat';
      if (userEmail) {
        setChatLoader(true);
        setLanguage(localStorage.getItem(`${getEntityId()}:language`));
        const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/user`, {
          method: 'POST',
          body: JSON.stringify({
            email: userEmail,
            fullname,
            entity: entityRecord,
            entityUrl,
            isNew: isNewMessage,
            userChatId: userChatId ? userChatId : null,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setLoading(true);
        const chatsData = await response.json();
        if (response.status === 200) {
          setChatId(chatsData?.data?.chats[0]._id);
          if (chatsData?.data?.chats[0]?.messages?.length > 0) {
            setUserId(chatsData?.data?.chats[0].client._id);
            if (chatsData?.data?.chats[0].STATUS === 'ASSIGNED') {
              setIsChatAssign(true);
              setStatus('Assigned');
              setAnimation(false);
              setLoading(false);
              setChatLoader(false);
            }
            setChatStatus(chatsData?.data?.chats[0].STATUS);
            setChat(chatsData?.data?.chats[0]?.messages);
            setAssign(chatsData?.data?.chats[0]);
            setLoading(false);
            setChatLoader(false);
          } else {
            setChat([]);
            setLoading(false);
            setChatLoader(false);
          }
        }
        setLoading(false);
        setChatLoader(false);
      }
    } catch (e) {
      setLoading(false);
      setChatLoader(false);
    }
  };

  const sendBotMessage = async (url, awsAudioUrl) => {
    const entity = 'chat';

    const endPoint = `${baseUrl}/${apiVersion}/${entity}/bot-message`;
    try {
      const response = await fetch(`${endPoint}`, {
        method: 'POST',
        body: JSON.stringify({
          email: userEmail,
          message,
          entity: entityRecord,
          image: url,
          fullname,
          audio: awsAudioUrl,
          language,
          entityUrl,
          isNew: isNewMessage,
          chatId,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const chatsData = await response.json();
      if (response.status === 200) {
        if (chatsData?.chats[0]?.messages?.length > 0) {
          setUserId(chatsData?.chats[0].client._id);
          setChat(chatsData?.chats[0]?.messages);
          setAssign(chatsData?.chats[0]);
        } else {
          setChat([]);
        }
        setAnimation(false);
      }
    } catch (e) {
      setUserId();
      setChat([]);
      setAssign();
      setAnimation(false);
    }
  };

  const postFileToDB = async (url) => {
    setUserMessage('');
    setMessage('');
    setShowEmoji(false);
    setIsSend(true);

    const entity = 'chat';
    const endPoint = `${baseUrl}/${apiVersion}/${entity}/usermessage`;
    try {
      const response = await fetch(`${endPoint}`, {
        method: 'POST',
        body: JSON.stringify({
          email: userEmail,
          message,
          entity: entityRecord,
          image: url,
          fullname,
          audio: audioUrl,
          language,
          entityUrl,
          chatId,
          isNew: isNewMessage,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const chatsData = await response.json();
      if (response.status === 200) {
        setProgress(0);
        setAudioUrl('');
        if (chatsData?.chats[0]?.messages?.length > 0) {
          setChatStatus(chatsData?.chats[0]?.STATUS);
          setUserId(chatsData?.chats[0].client._id);
          setChat(chatsData?.chats[0]?.messages);
          setAssign(chatsData?.chats[0]);
        } else {
          setChat([]);
        }
        clear();
        setAllFilesContent([]);
        setAllPlainFiles([]);
        setIsSend(false);
        setAnimation(true);
        setLoading(false);
        await sendBotMessage(url, audioUrl);
        setAudioUrl('');
        setIsSend(false);
      }
    } catch (e) {
      clear();
      setIsSend(false);
      setAnimation(false);
      setLoading(false);
      setAllFilesContent([]);
      setAllPlainFiles([]);
    }
  };

  async function processImages(items) {
    const fileLocation = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const response = await UploadFileToS3(
        `photos/${item.name}`,
        item,
        setProgress
      );
      fileLocation.push(response?.Location);
    }
    return fileLocation;
  }

  const sendMessage = async () => {
    try {
      handleCloseModal();
      setIsSend(true);
      if (allPlainFiles.length) {
        setIsSendImage(true);
        setLoading(true);
        const fileLocation = await processImages(allPlainFiles);
        postFileToDB(fileLocation);
        setIsSendImage(false);
        focusDiv.current.focus();
      } else if (audioUrl) {
        setLoading(true);
        setIsSendImage(true);
        await postFileToDB('');
        setIsSendImage(false);
        setLoading(false);
      } else if (message.trim()) {
        postFileToDB('');
      }
      setIsSend(false);
    } catch (e) {
      handleCloseModal();
      setIsSendImage(false);
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setShowEmoji(false);
      sendMessage();
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    focusDiv.current.focus();
    let tempString;
    if (userMessage) {
      tempString = `${userMessage} ${emojiObject.emoji}`;
    } else {
      tempString = emojiObject.emoji;
    }
    setUserMessage(tempString);
    setMessage(tempString);
  };

  useEffect(() => {
    setStatus('Assigned');
    if (!isNewMessage) {
      fetchChats();
    }
    return () => {
      setStatus('');
      setMessage('Assigned');
      setAssign([]);
    };
  }, []);

  const [recorder] = useState(new MicRecorder({ bitRate: 128 }));

  const stop = async () => {
    try {
      setAudioLoading(true);
      setIsRecording(false);
      const filename = `IC-${fullname}-${entityRecord}-${(Math.random() + 1).toString(36).substring(7)}.mp3`;
      recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const file = new File(buffer, filename, {
            type: blob.type,
            lastModified: Date.now(),
          });
          const mp3File = await UploadAudioToS3(
            `photos/${file.name}`,
            file
          );
          if (mp3File) {
            setAudioLoading(false);
            setAudioUrl(mp3File.Location);
          }
        });
    } catch (e) {
      setAudioLoading(false);
    }
  };
  useEffect(() => {
    const socket = io(process.env.REACT_APP_INVOCOM_API_URL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
      transports: ['websocket'],
      upgrade: false,
    });
    const urlAudio = new Audio();

    const id = localStorage.getItem(`${getEntityId()}:id`);
    socket.on(`sendNewMessageToClient-${userId}`, (updatedChat) => {
      urlAudio.src = sound;
      urlAudio.autoplay = true;
      urlAudio.type = 'audio/mp3';
      setTimeout(function () {
        urlAudio.play();
      }, 100);
      if (updatedChat?.client?._id === id && updatedChat?._id === userChatId) {
        setChat(updatedChat.messages);
      }
      urlAudio.pause();
    });
    socket.on(`newMessageFromClient-${entityRecord}`, (updatedChat) => {
      const email = localStorage.getItem(`${getEntityId()}:userEmail`);
      if (chat && JSON.stringify(updatedChat.client.email) === JSON.stringify(email)) {
        setChat(updatedChat.messages);
      }
    });
  }, []);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => {
      elementRef.current.scrollIntoView();
    }, []);
    return <div ref={elementRef} />;
  };

  const handleImage = (index) => {
    clear();
    allFilesContent.splice(index, 1);
    allPlainFiles.splice(index, 1);
    setLoading(false);
    setIsSend(false);
  };

  const openImageAttachment = () => {
    if (allPlainFiles.length < MAX_FILE_COUNT) {
      focusDiv.current.focus();
      openFileSelector();
      handleCloseModal();
    } else {
      handleOpenModal(`You can only select up to ${MAX_FILE_COUNT} files.`);
    }
  };

  const start = async () => {
    try {
      setIsRecording(true);
      setUserMessage('');
      setMessage('');
      navigator.mediaDevices
        .getUserMedia({ video: false, audio: true })
        .then((stream) => {
          window.location.localStream = stream;
          recorder.start();
        })
        .catch(() => {
          setIsRecording(false);
          setAudioLoading(false);
          recorder.stop();
          setRecord(false);
          handleOpenModal('Please Allow Microphone from browser settings');
        });
    } catch (e) {
      return false;
    }
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const secondsToHms = (value) => {
    return `${padTo2Digits(Math.floor(value / 60))}:${padTo2Digits(value % 60 ? value % 60 : '00')}`;
  };

  const startTimer = () => {
    time.current = -1;
    timer.current = setInterval(() => {
      time.current += 1;
      const getHMS = secondsToHms(time.current);
      setAudioTime(getHMS);
      if (getHMS === '02:01') {
        setRecord(false);
        stop();
      }
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timer.current);
    setAudioTime('00:00');
  };

  useEffect(() => {
    if (isRecording) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [isRecording]);

  useEffect(() => {
    if (record === true) {
      start();
    }
  }, [record]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (languageRef.current && !languageRef.current.contains(event.target)) {
        setShow(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [languageRef]);

  const handleDelete = () => {
    // Perform deletion logic
    setAudioUrl('');
    setShowConfirmation(false);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };
  useEffect(() => {
    const previousRoute = location?.state?.from;
    if (isQuestions === false && (previousRoute !== "email-transcript")) {
      skipQuestions();
    }
  }, [isQuestions]);

  const emojiFunction = (event) => {
    setShowEmoji(event);
    handleCloseModal();
  };

  return (
    <>
      <div className={`${isNewMessage ? 'new-chat-bot' : 'chat_bot'}`}>
        <div
          className={`${isNewMessage ? 'new-chat-body d-flex' : 'chatBody'} py-3 px-5`}
          style={{
            height: allFilesContent.length ? 'calc(486px - 55px)' : '',
          }} 
        >
          {(errors.length || showModal) && (
            <CustomAlertModal
              message={
                errors.length
                  ? errors[0]?.maxLimitExceeded
                    ? `Too many files selected, only ${MAX_FILE_COUNT - allPlainFiles.length} allow`
                    : errors
                  : modalMessage
              }
              onClose={handleCloseModal}
            />
          )}

          {showConfirmation && (
            <ConfirmationModal message="Are you sure you want to remove?" onConfirm={handleDelete} onCancel={handleCancel} />
          )}
          {isNewMessage && isQuestions ? (
            <>
              <div className="w-100 mt-3 mb-3">
                <p className="text-center greeting-message" style={{ wordBreak: 'break-all' }}>
                  {welcomeMessage}
                </p>
              </div>
              {renderQuestions()}
            </>
          ) : chatLoader ? (
            <img src={loadingGif} className="chat-loader" alt="loading" />
          ) : (
            <>{renderChat(chat)}</>
          )}
          {animation && assign.STATUS !== 'ASSIGNED' ? (
            <div className="flex justify-start p-1">
              <div className="chat_message_container">
                {/* <img className="botIcon" src={bot} alt="icon" style={{width: "35px"}} /> */}
                <Logo />
              </div>
              <div className="recieved p-3 pb-1 rounded-lg bg-secondary break-all relative">
                <div style={{ wordBreak: 'break-word' }}>
                  <div>
                    <div className="typing">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <AlwaysScrollToBottom />
        </div>
        <div>
          {!isNewMessage ? (
            chatStatus === 'CLOSED' ? (
              ''
            ) : (
              <div className="chatFooterOuter d-flex justify-between items-center relative pt-1">
                {audioUrl ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="audio-attachment-container">
                      <img
                        data-name="close"
                        alt="close"
                        onClick={() => setShowConfirmation(true)}
                        src={cross1}
                        loading="lazy"
                        className="attach-close-image"
                        style={{
                          borderRadius: '50%',
                          padding: '5px',
                        }}
                      />
                      <audio className="audio" src={audioUrl} controls />
                    </div>
                  </div>
                ) : (
                  <textarea
                    className="chat-input"
                    ref={focusDiv}
                    type="text"
                    value={userMessage}
                    autoComplete="true"
                    maxLength={200}
                    disabled={!!(record || audioUrl || audioLoading || loading)}
                    placeholder="Type your message here"
                    onClick={() => setShowEmoji(false)}
                    onChange={(e) => {
                      setShow(false);
                      setUserMessage(e.target.value);
                      setMessage(e.target.value);
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                  ></textarea>
                )}
                {showEmoji ? (
                  <div className="emoji_picker">
                    <Picker onEmojiClick={(event, emojiObject) => onEmojiClick(event, emojiObject)} />
                  </div>
                ) : (
                  ''
                )}
                <div
                  className="chatbot_footer d-flex items-center pr-2"
                  style={{ zIndex: '99999999999999', marginRight: '5px' }}
                >
                  {userMessage ? (
                    ''
                  ) : audioLoading ? (
                    <img src={loadingGif} style={{ width: '20px' }} alt="loading" />
                  ) : !record ? (
                    allPlainFiles.length ? (
                      ''
                    ) : record || audioUrl ? (
                      ''
                    ) : (
                      <Tooltip text="Audio" position="top">
                      <img
                        className="icon"
                        src={microPhone}
                        alt="url"
                        loading="lazy"
                        onClick={() => {
                          focusDiv.current.focus();
                          setRecord(true);
                        }}
                      />
                      </Tooltip>
                    )
                  ) : (
                    <>
                      <small style={{ marginLeft: '25px' }}>{audioTime}</small>
                      <img
                        src={stopRecorder}
                        alt="recording"
                        loading="lazy"
                        className="icon"
                        onClick={() => {
                          focusDiv.current.focus();
                          setRecord(false);
                          stop();
                        }}
                      />
                    </>
                  )}

                  {userMessage ? (
                    ''
                  ) : audioLoading ? (
                    <p style={{ fontSize: '12px' }}>Please Wait...</p>
                  ) : record || audioUrl || isSend === true ? (
                    ''
                  ) : (
                    <Tooltip text="Attach Files" position="top">
                    <img
                      src={attachment}
                      loading="lazy"
                      alt="attachment"
                      className="icon"
                      onClick={() => {
                        openImageAttachment();
                      }}
                    />
                    </Tooltip>
                  )}

                  {audioLoading ? (
                    ''
                  ) : record || audioUrl || isSend === true ? (
                    ''
                  ) : showEmoji ? (
                    <div>
                      <img
                        className="emoji-close"
                        style={{ height: '15px' }}
                        src={cross1}
                        loading="lazy"
                        alt="X"
                        onClick={() => setShowEmoji(!showEmoji)}
                      />
                    </div>
                  ) : (
                    <Tooltip text="Emoji" position="top">
                    <img
                      src={faceIcon}
                      className="icon"
                      loading="lazy"
                      alt="emoti"
                      onClick={() => emojiFunction(!showEmoji)}
                    />
                    </Tooltip>
                  )}
                  {audioTime === '00:00' ? (
                    isSend === false ? (
                      userMessage ? (
                        <Tooltip text="Send" position="top">
                          <button className="sendIcon" onClick={() => sendMessage()} onKeyPress={(e) => handleKeyPress(e)}>
                            <img src={sendButton} alt="send" loading="lazy" />
                          </button>
                        </Tooltip>
                      ) : audioLoading ? (
                        ''
                      ) : record || audioUrl || isSend === true ? (
                        <Tooltip text="Send" position="top">
                          <button className="sendIcon" onClick={() => sendMessage()} onKeyPress={(e) => handleKeyPress(e)}>
                            <img src={sendButton} alt="send" loading="lazy" />
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip text="Send" position="top">
                        <button className="sendIcon" onClick={() => sendMessage()} onKeyPress={(e) => handleKeyPress(e)}>
                          <img src={sendButton} alt="send" loading="lazy" />
                        </button>
                        </Tooltip>
                      )
                    ) : (
                      <>
                        <img src={loadingGif} style={{ width: '20px' }} alt="loading" />
                      </>
                    )
                  ) : (
                    ''
                  )}
                </div>
                <Footer audioUrl={audioUrl} allImages={allFilesContent} handleImage={(e) => handleImage(e)} progress={progress} />
              </div>
            )
          ) : (
            <a className="skipTxt" href="#" onClick={() => skipQuestions()}>
              Skip
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export default Chat;
