/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles/global.scss';
import '../../App.scss';
import BottomFooter from '../BottomFooter';
import { getEntityId } from '../../helpers/date-conversions';
import loadingGif from '../../assets/icons/loading.gif';

const BlogDetail = () => {
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const showBlog = async () => {
    try {
      setLoading(true);
      const baseUrl = process.env.REACT_APP_INVOCOM_API_URL;
      const apiVersion = process.env.REACT_APP_INVOCOM_API_VERSION;
      const entity = 'blog';
      const response = await fetch(`${baseUrl}/${apiVersion}/${entity}/show`, {
        method: 'POST',
        body: JSON.stringify({
          id: localStorage.getItem(`${getEntityId()}:blogId`),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        setLoading(false);
        setBlog(data.blog);
      }
    } catch (error) {}
  };
  useEffect(() => {
    showBlog();
  }, []);
  const handleBackArrow = () => {
    localStorage.setItem(`${getEntityId()}:blogId`, null);
    navigate('blog');
  };
  return (
    <div className="blog_detail blogs_screen">
      {loading ? (
        <div className="detail_body px-5  d-flex justify-center items-center ">
          <img src={loadingGif} style={{ width: '25px', height: '25px' }} alt="loading" />
        </div>
      ) : (
        <div className="detail_body px-5">
          <img className="img mb-2" src={blog?.image} alt="image" />
          <h1 className="detail_body_title mb-2" style={{wordWrap: "break-word"}}>{blog?.title}</h1>
          <div
            className="blogs_text"
            style={{ lineBreak: 'anywhere' }}
            dangerouslySetInnerHTML={{ __html: blog?.description }}
          />
        </div>
      )}

      <div className="pt-2 pb-2 d-flex justify-center">
        <BottomFooter active="blogs" />
      </div>
    </div>
  );
};

export default BlogDetail;
